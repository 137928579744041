import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'lib/common/format-phone-number';

const propTypes = {
  buttonClassName: PropTypes.string,
  sendAppDownloadText: PropTypes.func.isRequired,
};

const defaultProps = {
  buttonClassName: 'btn btn-blue',
};

const INPUT_CHANGE_TIMEOUT = 500;

export class TextToDownloadForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onPhoneNumberFieldChange = this.onPhoneNumberFieldChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const { sendAppDownloadText } = this.props;
    const { phone } = this;

    sendAppDownloadText({
      phone: phone.value,
    });
  }

  onPhoneNumberFieldChange(e) {
    clearTimeout(this.phoneNumberChangeTimeout);

    const { target } = e;
    let newNumber = target.value.replace(/\D/g, '');
    const maxLength = newNumber[0] === '1' ? 11 : 10;

    // Don't let user enter more numbaZ
    newNumber = newNumber.substring(0, maxLength);
    this.setState({ phone: newNumber });
    this.phoneNumberChangeTimeout = setTimeout(this.onPhoneNumberChange, INPUT_CHANGE_TIMEOUT);
  }

  render() {
    const { phone } = this.state;

    return (
      <div className="input-group cta-hero-input with-phone-icon">
        <form action="" id="appform" onSubmit={this.onSubmit}>
          <div className="display-flex">
            <input
              type="tel"
              id="phone"
              name="phone"
              className="form-control"
              placeholder="(xxx) xxx-xxxx"
              value={formatPhoneNumber(phone)}
              onChange={this.onPhoneNumberFieldChange}
              ref={(phoneNumber) => { this.phone = phoneNumber; }}
            />
            <button className={this.props.buttonClassName} type="submit">SEND LINK</button>
          </div>
        </form>
      </div>
    );
  }
}

TextToDownloadForm.propTypes = propTypes;
TextToDownloadForm.defaultProps = defaultProps;

export default TextToDownloadForm;

