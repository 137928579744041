import React from 'react';
import PropTypes from 'prop-types';

import { CONTENT_IMAGE } from 'components/common/how-it-works-carousel';

const propTypes = {
  contentStyle: PropTypes.string,
};

const defaultProps = {
  contentStyle: '',
};

const line1 = 'Find parking anywhere, for now or for later';
const line2 = 'Compare prices & pick the place that’s best for you';

const DiscoverAmazingSpaces = ({ contentStyle }) => (
  <div className="container">
    <div className="row">
      <div className="col-sm-6 col-sm-push-6 text-align-xs-center text-align-sm-left">
        <h2 className="text-color-dark-slate text-weight-black text-size-sm-42 text-size-xs-36 line-height-full margin-bottom-0 margin-top-md-100">
          <span className="text-color-blue">DISCOVER</span>
          <span className="display-block padding-left-sm-40">AMAZING</span>
          <span className="padding-left-sm-5">SPACES</span>
        </h2>
        <p className="visible-lg visible-md visible-sm text-color-dark-slate text-size-sm-14 text-size-xs-12 margin-bottom-0 margin-top-20">{ line1 }</p>
        <p className="visible-lg visible-md visible-sm text-color-dark-slate text-size-sm-14 text-size-xs-12 margin-bottom-0 margin-top-10">{ line2 }</p>
      </div>
      <div className="col-sm-6 col-sm-pull-6 col-xs-12 text-align-xs-center">
        { (contentStyle === CONTENT_IMAGE) &&
          <img src="https://s3.amazonaws.com/random-static.parkwhiz/videos/how-it-works-discover-amazing-spaces-pw-bp@2x.jpg" alt="Discover Amazing Space" />
        }
        <p className="visible-xs text-color-dark-slate text-size-xs-16 margin-bottom-10 padding-horizontal-20">{ line1 }</p>
        <p className="visible-xs text-color-dark-slate text-size-xs-16 margin-bottom-0 padding-horizontal-40">{ line2 }</p>
      </div>
    </div>
  </div>
);

DiscoverAmazingSpaces.propTypes = propTypes;
DiscoverAmazingSpaces.defaultProps = defaultProps;

export default DiscoverAmazingSpaces;
