import React from 'react';

const BasicValueProps = () => (
  <div className="background-color-off-white padding-vertical-40 text-color-dark-slate text-weight-book">
    <div className="container">
      <div className="row">
        <div className="col-md-4 margin-bottom-xs-20 margin-bottom-md-0">
          <div className="row">
            <div className="col-md-2 col-gutter-right-md-0 text-align-xs-center text-align-md-right">
              <span className="iconified-font iconified-phone text-size-36" />
            </div>
            <div className="col-md-10 text-align-xs-center text-align-md-left text-size-xs-14 text-size-md-16">
              <div className="text-weight-black">
                Hassle-Free Booking
              </div>
              Reserve a space with a few taps and skip the parking hunt.
            </div>
          </div>
        </div>

        <div className="col-md-4 col-gutter-right-md-0 margin-bottom-xs-20 margin-bottom-md-0">
          <div className="row">
            <div className="col-md-2 col-gutter-right-md-0 text-align-xs-center text-align-md-right">
              <span className="iconified-font iconified-payment text-size-36" />
            </div>
            <div className="col-md-10 text-align-xs-center text-align-md-left text-size-xs-14 text-size-md-16">
              <div className="text-weight-black">
                No-Surprise Pricing
              </div>
              Compare prices, see your total cost up-front, and save up to 50%.
            </div>
          </div>
        </div>

        <div className="col-md-4 col-gutter-right-md-0">
          <div className="row">
            <div className="col-md-2 col-gutter-right-md-0 text-align-xs-center text-align-md-right">
              <span className="iconified-font iconified-zag text-size-36" />
            </div>
            <div className="col-md-10 text-align-xs-center text-align-md-left text-size-xs-14 text-size-md-16">
              <div className="text-weight-black">
                Lightning-Fast Parking
              </div>
              Park your car in seconds and go do your thing.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BasicValueProps;

