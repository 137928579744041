import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { CONTENT_IMAGE, CONTENT_VIDEO } from 'components/common/how-it-works-carousel';

import Brand from 'models/brand';

const propTypes = {
  contentStyle: PropTypes.string,
  brand: PropTypes.instanceOf(Brand),
};

const defaultProps = {
  contentStyle: '',
  brand: null,
};

const line1 = 'Book a space in just a few easy clicks';
const line2 = 'Save up to 50% off standard rates';

const ReservePrepaySave = ({ contentStyle, brand }) => {
  return (
  <div className="container">
    <div className="row">
      <div className="col-sm-4 col-sm-offset-2 text-align-xs-center text-align-sm-left">
        <h2 className="text-color-dark-slate text-weight-black text-size-sm-42 text-size-xs-36 line-height-full margin-bottom-10 margin-top-md-150">
          RESERVE
          <span className="text-color-blue display-block padding-left-sm-40">PREPAY</span>
          <span className="padding-left-sm-10">& SAVE</span>
        </h2>
        <p className="visible-lg visible-md visible-sm text-color-dark-slate text-size-sm-14 text-size-xs-12 margin-bottom-0 margin-top-20">{ line1 }</p>
        <p className="visible-lg visible-md visible-sm text-color-dark-slate text-size-sm-14 text-size-xs-12 margin-bottom-0 margin-top-10">{ line2 }</p>
      </div>
      <div className="col-sm-4 col-xs-12 text-align-xs-center">
        { (contentStyle === CONTENT_IMAGE) &&
          <img src={`https://s3.amazonaws.com/random-static.parkwhiz/videos/how-it-works-reserve-prepay-save-${get(brand, 'isBestParking', false) ? 'bp' : 'pw'}@2x.jpg`} alt="Reserve, Prepay, Save" />
        }
        <p className="visible-xs text-color-dark-slate text-size-xs-16 margin-bottom-10 margin-top-10 padding-horizontal-10">{ line1 }</p>
        <p className="visible-xs text-color-dark-slate text-size-xs-16 margin-bottom-0 padding-horizontal-60">{ line2 }</p>
      </div>
    </div>
  </div>
)};

ReservePrepaySave.propTypes = propTypes;
ReservePrepaySave.defaultProps = defaultProps;

export default ReservePrepaySave;
