import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowCarousel from 'components/common/carousels/arrow-carousel';
import BrandName from 'containers/common/brand-name';
import Brand from 'models/brand';

const propTypes = {
  className: PropTypes.string,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

const defaultProps = {
  className: '',
};

const PARKWHIZ_REVIEWS = [
  {
    author: 'Ed A. | Phoenix, AZ',
    comment: 'Using ParkWhiz was awesome! I didn\'t have to walk forever or drive around trying to find a spot.',
    id: 1,
  },
  {
    author: 'Carl N. | Phoenix, AZ',
    comment: 'Outstanding parking. I am glad I used ParkWhiz. Made me feel like a VIP for only 12 bucks.',
    id: 2,
  },
  {
    author: 'Brooke C. | Philadelphia, PA',
    comment: 'I have never been in and out of a parking garage after a concert so fast…Best experience in a parking garage ever.',
    id: 3,
  },
  {
    author: 'Heather P. | Boston, MA',
    comment: 'I do not head to Boston concerts or events without ParkWhiz! Search, reserve, pay, park, it\'s that easy.',
    id: 4,
  },
  {
    author: 'Travis C. | Chicago, IL',
    comment: 'Normally I pay around $50 for parking in Chicago, but with ParkWhiz I was able to get a spot for $18 downtown. The price you see is the price you pay!',
    id: 5,
  },
  {
    author: 'Brooke C. | New York, NY',
    comment: 'I completely forgot how much of a hassle parking gets and was in a rush…Using ParkWhiz, I found a spot and was there within seconds!',
    id: 6,
  },
];

const BESTPARKING_REVIEWS = [
  {
    author: 'Sharon P.',
    comment: 'BestParking gives me peace of mind... My very first step is to go to BestParking to set up my parking so that\'s one less thing on my worry list.',
    id: 1,
  },
  {
    author: 'Elizabeth C.',
    comment: 'Saves me time and money. I\'ve saved hundreds of dollars per year and can drive into the city with confidence I\'m getting the best rate.',
    id: 2,
  },
  {
    author: 'Elizabeth S.',
    comment: 'Super fast, simple, easy to find multiple choices in any given area. A VERY powerful app!',
    id: 3,
  },
  {
    author: 'Bob V.',
    comment: 'It provides convenient and clear options for money-saving parking. It\'s reliable. We\'ve come to depend on BestParking.',
    id: 4,
  },
  {
    author: 'Ellen D.',
    comment: 'I can choose where I want to park, fix the rate, and even reserve my spot. It has never failed me.',
    id: 5,
  },
  {
    author: 'Doug Y.',
    comment: 'Having this app means I get to choose not only the price and see the savings but the location and what fits my needs the best. It\'s the most useful app I have.',
    id: 6,
  },
];

export class PeopleLoveParkWhiz extends Component {
  reviews() {
    return this.props.brand.isBestParking ? BESTPARKING_REVIEWS : PARKWHIZ_REVIEWS;
  }

  slides() {
    return this.reviews().map(review => (
      <div className="padding-horizontal-xs-40 padding-horizontal-md-120 text-color-dark-slate" key={`review-${review.id}`}>
        <p className="font-family-georgia text-size-md-18">{ review.comment }</p>
        <p className="text-weight-black text-size-md-18">{ review.author }</p>
      </div>
    ));
  }

  render() {
    const { className } = this.props;
    const mergedClassName = `container text-align-center ${className}`;

    return (
      <div className={mergedClassName}>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <h2 className="text-weight-black text-color-dark-slate text-size-xs-28 text-size-md-42 line-height-full margin-top-0 margin-bottom-40">
              PEOPLE <span className="iconified-font iconified-heart text-color-watermelon" /> <BrandName className="uppercase" />
            </h2>
            <div className="heart-parkwhiz-carousel">
              <ArrowCarousel
                slides={this.slides()}
                autoplay
                autoplayInterval={6000}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PeopleLoveParkWhiz.propTypes = propTypes;
PeopleLoveParkWhiz.defaultProps = defaultProps;

export default PeopleLoveParkWhiz;

