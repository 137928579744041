export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) { return ''; }

  const length = phoneNumber.length;
  let formattedNumber = phoneNumber;

  if (phoneNumber[0] === '1') {
    formattedNumber = `+${phoneNumber}`;
    if (length > 1) { formattedNumber = `${formattedNumber.substring(0, 2)} (${formattedNumber.substring(2)}`; }
    if (length > 4) { formattedNumber = `${formattedNumber.substring(0, 7)}) ${formattedNumber.substring(7)}`; }
    if (length > 7) { formattedNumber = `${formattedNumber.substring(0, 12)}-${formattedNumber.substring(12)}`; }
  } else {
    if (length > 0) { formattedNumber = `(${formattedNumber}`; }
    if (length > 3) { formattedNumber = `${formattedNumber.substring(0, 4)}) ${formattedNumber.substring(4)}`; }
    if (length > 6) { formattedNumber = `${formattedNumber.substring(0, 9)}-${formattedNumber.substring(9)}`; }
  }

  return formattedNumber;
}
