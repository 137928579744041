import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DotPageCarousel from 'components/common/carousels/dot-page-carousel';

const propTypes = {
  className: PropTypes.string,
  autoplayInterval: PropTypes.number,
  initialSlideHeight: PropTypes.number,
  contentStyle: PropTypes.string,
};

const defaultProps = {
  className: '',
  autoplayInterval: 3000,
  initialSlideHeight: 634,
  contentStyle: '',
};

import DiscoverAmazingSpaces from './how-it-works/discover-amazing-spaces';
import ReservePrepaySave from './how-it-works/reserve-prepay-save';
import DriveArrivePark from './how-it-works/drive-arrive-park';

const HowItWorksCarousel = ({ className, autoplayInterval, initialSlideHeight, contentStyle }) => (
  <DotPageCarousel
    className={`bottom-shadow-blur ${className}`}
    autoplayInterval={autoplayInterval}
    initialSlideHeight={initialSlideHeight}
    wrapAround
  >
    <DiscoverAmazingSpaces
      contentStyle={contentStyle}
    />
    <ReservePrepaySave
      contentStyle={contentStyle}
    />
    <DriveArrivePark
      contentStyle={contentStyle}
    />
  </DotPageCarousel>
);

HowItWorksCarousel.propTypes = propTypes;
HowItWorksCarousel.defaultProps = defaultProps;

export default HowItWorksCarousel;

export const CONTENT_VIDEO = 'video';
export const CONTENT_IMAGE = 'image';
export const CONTENT_TEXT = 'text';

