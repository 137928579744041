import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { imageCdnAsset } from 'lib/common/url-helpers';

import Brand from 'models/brand';

import AppLink from 'containers/common/app-link';
import BrandName from 'containers/common/brand-name';
import TextToDownloadForm from 'components/common/text-to-download/form';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  className: PropTypes.string,
  sendAppDownloadText: PropTypes.func.isRequired,
  appButtonPosition: PropTypes.number,
};

const defaultProps = {
  className: '',
  appButtonPosition: null,
};

const TextToDownload = ({ brand, className, sendAppDownloadText, appButtonPosition }) => {
  if (!brand.showAppDownloadInformation) { return null; }

  return (
    <div className={`container ${className}`}>
      <div className="row text-align-xs-center text-align-md-left">
        <div className="col-md-7 col-md-offset-1 col-gutter-xs-30 col-gutter-md-15">
          <h2 className={`margin-top-120 text-color-xs-dark-slate ${brand.isBestParking ? 'text-color-md-bp-blue' : 'text-color-md-watermelon'} text-weight-xs-bold text-weight-md-black text-size-xs-20 text-size-md-28 margin-bottom-xs-20 margin-bottom-md-5 margin-top-0 line-height-extra`}>
            Download the FREE <BrandName /> App
          </h2>
          <p className="text-color-dark-slate">
            Tap into awesome parking anywhere on iOS & Android.
          </p>
          <div className="row hidden-xs hidden-sm">
            <div className="col-md-9 margin-top-10">
              <p className="text-color-dark-slate margin-bottom-0">
                Text yourself a link to download.
              </p>
              <TextToDownloadForm
                buttonClassName={`btn ${brand.isBestParking ? 'btn-bp' : 'btn-watermelon'}`}
                sendAppDownloadText={sendAppDownloadText}
                brand={brand}
              />
              <p className="text-color-dark-slate text-weight-book margin-top-5 text-align-right">
                Standard message rates may apply
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 visible-md visible-lg">
          <picture>
            <source media="(max-width: 991px)" srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
            <img src={`https://s3.amazonaws.com/random-static.parkwhiz/videos/app-phone-mock-${get(brand, 'isBestParking', false) ? 'bp' : 'pw'}@2x.jpg`} alt="" />
          </picture>
        </div>
        <div className="col-xs-12 visible-xs visible-sm">
          <div>
            <AppLink appStore="apple" linkProps={{ c: 'text_to_downlod_component' }} analyticsPosition={appButtonPosition} className="display-block" mobileOnly>
              <img
                className="center-block background-color-black padding-vertical-10 padding-horizontal-20 border-radius-8"
                height="67"
                src={imageCdnAsset('/images/app-badges/app-badge-apple-bare@2x.png')}
                width="183"
              />
            </AppLink>
            <AppLink appStore="android" linkProps={{ c: 'text_to_downlod_component' }} analyticsPosition={appButtonPosition} className="display-block margin-top-20" mobileOnly>
              <img
                className="center-block background-color-black padding-vertical-10 padding-horizontal-20 border-radius-8"
                height="67"
                src={imageCdnAsset('/images/app-badges/app-badge-android-bare@2x.png')}
                width="183"
              />
            </AppLink>
          </div>
        </div>
      </div>
    </div>
  );
};

TextToDownload.propTypes = propTypes;
TextToDownload.defaultProps = defaultProps;

export default TextToDownload;
