import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import DiscoverAmazingSpaces from 'components/common/how-it-works/discover-amazing-spaces';
import ReservePrepaySave from 'components/common/how-it-works/reserve-prepay-save';
import DriveArrivePark from 'components/common/how-it-works/drive-arrive-park';
import { CONTENT_IMAGE, CONTENT_VIDEO } from 'components/common/how-it-works-carousel';

import Brand from 'models/brand';

import * as AppContext from 'lib/app-context';

const propTypes = {
  appContext: PropTypes.string,
  brand: PropTypes.instanceOf(Brand),
};

const defaultProps = {
  appContext: AppContext.MOBILE,
  brand: null,
};

const HowItWorks = ({ appContext, brand }) => (
  <div className="container">
    <DiscoverAmazingSpaces
      contentStyle={CONTENT_IMAGE}
    />
    <ReservePrepaySave
      contentStyle={CONTENT_IMAGE}
      brand={brand}
    />
    <DriveArrivePark
      contentStyle={CONTENT_IMAGE}
    />
  </div>
);

HowItWorks.propTypes = propTypes;
HowItWorks.defaultProps = defaultProps;

export default HowItWorks;
