import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

const propTypes = {
  autoplay: PropTypes.bool,
  autoplayInterval: PropTypes.number,
  slide: PropTypes.number,
  slides: PropTypes.array.isRequired,
};

const defaultProps = {
  autoplay: false,
  autoplayInterval: 3000,
  slide: 0,
};

class ArrowCarousel extends Component {
  previousSlideArrow() {
    return (
      {
        component: createReactClass({
          render() {
            if (this.props.slideCount > 1) {
              return (
                <span
                  className="iconified-font iconified-chevron-left text-color-blue text-size-28 padding-xs-10 padding-md-20 clickable"
                  onClick={this.props.previousSlide}
                />
              );
            }
            return null;
          },
        }),
        position: 'CenterLeft',
      }
    );
  }

  nextSlideArrow() {
    return (
      {
        component: createReactClass({
          render() {
            if (this.props.slideCount > 1) {
              return (
                <span
                  className="iconified-font iconified-chevron-right text-color-blue text-size-28 padding-xs-10 padding-md-20 clickable"
                  onClick={this.props.nextSlide}
                />
              );
            }
            return null;
          },
        }),
        position: 'CenterRight',
      }
    );
  }

  render() {
    const { slides, slide, autoplay, autoplayInterval } = this.props;
    const decorators = [
      this.previousSlideArrow(),
      this.nextSlideArrow(),
    ];

    return (
      <Carousel
        decorators={decorators}
        dragging={slides.length > 1}
        slideIndex={slide}
        wrapAround
        autoplay={autoplay}
        autoplayInterval={autoplayInterval}
      >
        { slides }
      </Carousel>
    );
  }
}

ArrowCarousel.propTypes = propTypes;
ArrowCarousel.defaultProps = defaultProps;

export default ArrowCarousel;

