import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import VisibilitySensor from 'react-visibility-sensor';

const propTypes = {
  className: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplayInterval: PropTypes.number,
  slide: PropTypes.number,
  slideCount: PropTypes.number,
  currentSlide: PropTypes.number,
  slides: PropTypes.array.isRequired,
  slidesToScroll: PropTypes.array,
  wrapAround: PropTypes.bool,
  initialSlideHeight: PropTypes.number,
};

const defaultProps = {
  className: '',
  autoplay: false,
  autoplayInterval: 3000,
  slide: 0,
  slideCount: 0,
  currentSlide: 0,
  slides: [],
  slidesToScroll: [],
  wrapAround: true,
  initialSlideHeight: 634,
};

class DotPageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { autoplay: false };
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  onVisibilityChange(isVisible) {
    this.setState({ autoplay: isVisible });
  }

  slideProgress() {
    return (
      {
        linter: this,
        component: createReactClass({
          render() {
            const indexes = this.getIndexes(this.props.slideCount, this.props.slidesToScroll);
            return (
              <ul className="list-inline">
                {
                  indexes.map(index => (
                    <li className={'text-color-slate'} key={index}>
                      <button
                        className={`btn-transparent opacity-50 text-size-24 padding-5 ${this.getActiveButton(this.props.currentSlide === index)}`}
                        onClick={this.props.goToSlide.bind(null, index)}
                      >
                          &bull;
                      </button>
                    </li>
                  ))
                }
              </ul>
            );
          },
          getIndexes(count, inc) {
            const arr = [];
            for (let i = 0; i < count; i += inc) {
              arr.push(i);
            }
            return arr;
          },
          getActiveButton(active) {
            return active ? 'text-color-dark-slate opacity-100' : '';
          },
        }),
        position: 'BottomCenter',
      }
    );
  }
  render() {
    const { slides, slide, autoplay, autoplayInterval, className, wrapAround, initialSlideHeight } = this.props;
    const mergedClassName = `dot-page-carousel ${className}`;
    const decorators = [
      this.slideProgress(),
    ];
    return (
      <VisibilitySensor onChange={this.onVisibilityChange} partialVisibility>
        <Carousel
          className={mergedClassName}
          decorators={decorators}
          wrapAround={wrapAround}
          autoplayInterval={autoplayInterval}
          initialSlideHeight={initialSlideHeight}
          autoplay={this.state.autoplay}
        >
          { this.props.children }
        </Carousel>
      </VisibilitySensor>
    );
  }
}

DotPageCarousel.propTypes = propTypes;
DotPageCarousel.defaultProps = defaultProps;

export default DotPageCarousel;

